<template>
	<div>
		<div
			class="h4 text-muted mt-3">
			<button
				type="button"
				class="btn btn-link text-muted"
				style="font-size: large;"
				@click="$router.push({ name: 'TransferFeeProduct' })">
				<i class="fa fa-angle-left" />
			</button>
			{{ translate('go_back') }}
		</div>
		<div class="row">
			<div class="col d-flex justify-content-center">
				<div
					:class="!cartOptions.is_pack ? 'mt-3' : ''"
					class="h2">
					{{ translate('purchase_confirmation') }}
				</div>
			</div>
		</div>
		<b-alert
			v-if="hasPurchaseErrors"
			class="mt-2"
			show
			variant="danger">
			<h5 class="font-weight-bold">
				<i class="fa fa-exclamation-triangle" /> {{ translate('verify_following_errors') }}:
			</h5>
			<ul class="h6">
				<template v-for="(error, index) in purchaseErrors">
					<li :key="index">
						{{ error }}
					</li>
				</template>
			</ul>
		</b-alert>
		<div
			:class="['xs'].includes(windowWidth) ? 'mt-2' : 'mt-3'"
			class="row mb-5">
			<div class="col-12 col-md-8">
				<div class="row">
					<div class="col-12">
						<flow-info-group>
							<flow-info-section
								:compact="isCompact('TransferFeeShipping')"
								:init-edit="isEditing('TransferFeeShipping')"
								:title="`${translate('shipping_information')}`"
								:hide-controls="isEditing('TransferFeeShipping') || loadingInitialInformation"
								active-color="dark"
								hide-cancel
								override-edit
								:no-inner-spacing="isEditing('TransferFeeShipping')"
								@toggled="editStep('TransferFeeShipping')">
								<shipping-overview
									v-if="!isEditing('TransferFeeShipping')"
									:loading-override="loadingInitialInformation"
									:shipping-method-import="shippingMethod"
									:override-shipping-method="!loadingInitialInformation && !systemLoading"
									@noData="editStep('TransferFeeShipping')" />
								<shipping-edit
									v-if="isEditing('TransferFeeShipping')"
									:cart-id="cartId"
									@cancel="handleCancelledStep('TransferFeeShipping')"
									@cartValidationError="redirectToProducts"
									@invalidRequest="handleInvalidRequest"
									@saved="handleSavedStep('TransferFeeShipping')" />
							</flow-info-section>
							<flow-info-section
								:compact="isCompact('TransferFeePayment')"
								:init-edit="isEditing('TransferFeePayment')"
								:hide-controls="isEditing('TransferFeePayment') || loadingInitialInformation"
								:title="`${translate('payment_method')}`"
								active-color="dark"
								hide-cancel
								override-edit
								:no-inner-spacing="isEditing('TransferFeePayment')"
								@toggled="editStep('TransferFeePayment')">
								<payment-overview
									v-if="!isEditing('TransferFeePayment')"
									:loading-override="loadingInitialInformation"
									@changePaymentMethodSelected="changePaymentMethodSelected"
									@noData="editStep('TransferFeePayment')" />
								<payment-edit
									v-if="isEditing('TransferFeePayment')"
									:payment-method-conditions="paymentMethodConditions"
									:cart-id="cartId"
									@cancel="handleCancelledStep('TransferFeePayment')"
									@cartValidationError="redirectToProducts"
									@invalidRequest="handleInvalidRequest"
									@saved="handleSavedStep('TransferFeePayment')"
									@changePaymentMethodSelected="changePaymentMethodSelected" />
							</flow-info-section>
						</flow-info-group>
					</div>
					<div class="col-12">
						<div class="row mt-4 pl-4">
							<div class="col">
								<h5>{{ translate('review_your_cart_items') }}</h5>
							</div>
						</div>
						<div class="row mt-1">
							<div
								class="col">
								<cart-items
									:loading="systemLoading || loadingInitialInformation"
									:cart-items="cartProducts"
									:qty-import="qty"
									:editing-disabled="true"
									hide-loading
									:is-pack="!!cartOptions.is_pack" />
							</div>
						</div>
						<is-loading
							:loading-label="translate('loading')"
							:no-data-label="translate('empty_cart')"
							:loading="systemLoading || loadingInitialInformation"
							:has-data="cartProducts.length > 0" />
						<hr class="my-3 w-100">
					</div>
				</div>
			</div>
			<div
				:class="{ 'mt-3': ['xs', 'sm'].includes(windowWidth) }"
				class="col-12 col-md-4">
				<order-summary
					:cart-id="cartId"
					:package-code-name="cartOptions.package_code_name || ''"
					:is-pack="!!cartOptions.is_pack"
					:cart-loading="systemLoading || loadingInitialInformation"
					:cart-totals="cartTotals"
					:cart-points="cartPoints"
					:cart-small-text-totals="cartSmallTextTotals"
					:cart-items="cartProducts"
					:cart-total="cartTotal"
					:total-volume="totalVolume"
					:clicked-payment-method="clickedPayment"
					:cart-coupon="cartCoupon"
					:coupon-errors="couponErrors"
					:coupon-loading="couponLoading"
					:remove-coupon-loading="removeCouponLoading"
					:exchange="exchange.is_needed ? exchange.total : ''"
					:terms-and-policies="termsAndPolicies"
					:editing-other-steps="isEditingAnyStep"
					:upgrade-packages="upgradePackages"
					:upgrade-volume="upgradeVolume"
					:has-upgrade-packages="hasUpgradePackages"
					:discount-detail="discountDetail"
					:style="['xs', 'sm'].includes(windowWidth) ? '' : `top: 85px !important;`"
					:class="['xs', 'sm'].includes(windowWidth) ? '' : 'sticky-top'"
					@cartValidationError="redirectToProducts"
					@invalidRequest="handleInvalidRequest"
					@serverError="redirectToProducts"
					@purchaseClick="handleOrderClick"
					@purchaseErrors="handlePurchaseErrors"
					@cartReplaced="handleCartReplaced"
					@splitPayment="splitPayment"
					@reloadCart="getCartTotals" />
			</div>
		</div>
		<b-alert
			v-if="hasPurchaseErrors && ['xs','sm'].includes(windowWidth)"
			class="mt-2"
			show
			variant="danger">
			<h5 class="font-weight-bold">
				<i class="fa fa-exclamation-triangle" /> {{ translate('verify_following_errors') }}:
			</h5>
			<ul class="h6">
				<template v-for="(error, index) in purchaseErrors">
					<li :key="index">
						{{ error }}
					</li>
				</template>
			</ul>
		</b-alert>
	</div>
</template>
<script>
import CartItems from '@/components/Cart/CartItems';
import FlowInfoGroup from '@/components/FlowInfo/Group';
import FlowInfoSection from '@/components/FlowInfo/Section';
import IsLoading from '@/components/Loading';
import CartData from '@/mixins/CartData';
import PaymentMethods from '@/mixins/PaymentMethods';
import DocumentTitle from '@/mixins/DocumentTitle';
import WindowSizes from '@/mixins/WindowSizes';
import { EXPIRATION_VALUE, EXPIRATION_TIME } from '@/settings/Cookie';
import { distributor } from '@/settings/Roles';
import {
	Cart, Grids, Profile, Purchase as PurchaseMessages, Validations, Events,
} from '@/translations';
import Purchase from '@/util/Purchase';
import OrderSummary from './components/Confirmation/OrderSummary';
import PaymentOverview from './components/Payment/Overview';
import PaymentEdit from './components/Payment/Edit';
import ShippingEdit from './components/Shipping/Edit';
import ShippingOverview from './components/Shipping/Overview';
import Steps from './mixins/Steps';
import store from './store';

export default {
	name: 'PackageConfirmation',
	messages: [Cart, Grids, Profile, PurchaseMessages, Validations, Events],
	components: {
		CartItems,
		FlowInfoGroup,
		FlowInfoSection,
		IsLoading,
		OrderSummary,
		PaymentOverview,
		PaymentEdit,
		ShippingOverview,
		ShippingEdit,
	},
	mixins: [CartData, PaymentMethods, DocumentTitle, Steps, WindowSizes],
	data() {
		return {
			purchaseInfo: new Purchase(),
			distributor,
			canLeave: false,
			editing: {
				TransferFeeShipping: false,
				TransferFeePayment: false,
				TransferFeeConfirmation: false,
			},
			purchaseErrors: [],
			clickedPayment: '',
		};
	},
	computed: {
		loadingInitialInformation() {
			return this.purchaseInfo.data.loading;
		},
		isEditingAnyStep() {
			return Object.keys(this.editing)
				.filter((stepName) => stepName !== 'TransferFeeConfirmation')
				.some((stepName) => this.editing[stepName]);
		},
		hasPurchaseErrors() {
			return !!this.purchaseErrors.length;
		},
		paymentMethodSelected() {
			const { payment } = { ...store.getters.getStepInformation('TransferFeePayment') };
			// This linting disabler must be removed when the linter is upgraded
			// See: https://github.com/babel/eslint-plugin-babel/pull/163
			// eslint-disable-next-line camelcase
			return payment?.payment_method?.name || this.clickedPayment;
		},
	},
	watch: {
		cartProducts(newVal) {
			if (!newVal.length) {
				this.alert.toast('error', this.translate('empty_cart'), { timer: 6000 });
				setTimeout(() => {
					this.redirectToProducts();
				}, 6000);
			}
		},
		paymentMethodConditions: {
			deep: true,
			handler() {
				if (typeof this.paymentMethodConditions[this.paymentMethodSelected] !== 'undefined' && !this.paymentMethodConditions[this.paymentMethodSelected]?.isAvailable) {
					this.resetPaymentMethod();
				}
			},
		},
		clickedPayment(newVal) {
			if (typeof this.paymentMethodConditions[newVal] !== 'undefined' && !this.paymentMethodConditions[newVal]?.isAvailable) {
				this.editStep('TransferFeePayment');
			}
		},
		cartNumericTotal(total) {
			this.setAmountToPay(total);
		},
	},
	async mounted() {
		this.setFlowName('purchase');
		await store.dispatch('removeInfo');
		await this.getStartingInfo();
		if (typeof this.paymentMethodConditions[this.paymentMethodSelected] !== 'undefined' && !this.paymentMethodConditions[this.paymentMethodSelected]?.isAvailable) {
			await this.resetPaymentMethod();
		}
	},
	methods: {
		async getStartingInfo() {
			const response = await this.purchaseInfo.getPurchaseInformation();
			if (this.$route.params.storedCartId) {
				response.response.cart_id = this.$route.params.storedCartId;
			}
			if (response.response.is_pack) {
				this.cartOptions = { is_pack: 1, package_code_name: response.response.package_code_name };
			}
			await store.dispatch('saveInitialInformation', response.response);
			return this.setCartCookie(response.response.cart_id, { expired: `${EXPIRATION_VALUE}${EXPIRATION_TIME}` });
		},
		completedSteps() {
			return store.getters.getCompletedSteps();
		},
		stepIsCompleted(step) {
			return this.completedSteps().includes(step);
		},
		isEditing(step) {
			return this.editing[step];
		},
		editStep(step) {
			this.editing[step] = true;
		},
		setStepAsNotEditing(step) {
			this.editing[step] = false;
		},
		isCompact(step) {
			return !this.isEditing(step) && this.stepIsCompleted(step);
		},
		handleCancelledStep(step) {
			this.setStepAsNotEditing(step);
		},
		handleSavedStep(step) {
			this.getCartTotals();
			this.setStepAsNotEditing(step);
		},
		changePaymentMethodSelected(selected) {
			if (selected !== 0) this.clickedPayment = selected;
		},
		handleInvalidRequest() {
			this.createNewCart();
			this.alert.toast('error', this.translate('something_went_wrong'), { timer: 6000 });
			setTimeout(() => {
				this.resetStepsAndRedirect();
			}, 6000);
		},
		handlePurchaseErrors(purchaseErrors) {
			this.purchaseErrors = purchaseErrors;
		},
		handleOrderClick() {
			this.purchaseErrors = [];
		},
		handleCartReplaced(cartId) {
			this.setCartCookie(cartId, { expired: `${EXPIRATION_VALUE}${EXPIRATION_TIME.minutes}` });
		},
		resetStepsAndRedirect() {
			store.dispatch('removeInfo').then(() => {
				this.redirectToProducts();
			}).catch(() => {});
		},
		redirectToProducts() {
			this.$router.replace({ name: 'TransferFeeProduct' });
		},
		resetPaymentMethod() {
			const paymentInformation = store.getters.getStepInformation('TransferFeePayment');
			const data = {
				TransferFeePayment: {
					payment: {
						billing: {
							address_id: 0,
						},
					},
				},
			};
			if (paymentInformation.payment) {
				data.TransferFeePayment.payment.billing.address_id = paymentInformation.payment.billing.address_id;
			}
			return store.dispatch('saveInfo', data)
				.then(() => store.dispatch('saveStepAsIncomplete', 'TransferFeePayment'))
				.then(() => this.editStep('TransferFeePayment'));
		},
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (!vm.$user.details().pay_transfer_account_fee) {
				vm.$router.replace({ name: 'MainHome' });
			}
		});
	},
	beforeRouteLeave(to, from, next) {
		if (to.name.includes('TransferFee')) {
			next();
			const alert = new this.$Alert();
			alert.close();
		} else if (to.name.includes('Store')) {
			if (this.canLeave || !this.$user.details().pay_transfer_account_fee) {
				next();
				const alert = new this.$Alert();
				alert.close();
			} else {
				next();
			}
		} else if (to.name.includes('Home')) {
			next();
			const alert = new this.$Alert();
			alert.close();
		}
	},
};
</script>
